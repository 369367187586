import { manifestMeta } from 'theme/meta/base';
import { mergeMeta } from 'theme/meta/utils/mergeMeta';
import { relRobotsHandler } from 'theme/meta/utils/robots';
import { RobotsEnum } from './types'
import { htmlDecode } from '@vue-storefront/core/filters';
import config from 'config';
import store from '@vue-storefront/core/store'
import i18n from '@vue-storefront/i18n'
import { exclusionUrlsListUa, exclusionUrlsListRu } from 'theme/meta/resource/exclusionUrlsList'
import { getThumbnailPath } from '@vue-storefront/core/helpers';

const getPriceFilter = () => {
  let priceSelected = []
  const route = store.getters['url/getCurrentRoute']
  if (route.params.price !== undefined) {
    if (Array.isArray(route.params.price)) {
      priceSelected = route.params.price[0].split('-')
    } else {
      priceSelected = route.params.price.split('-')
    }
  }
  return `${i18n.t('Price')}, грн ${priceSelected[0]}-${priceSelected[1]}`
}

const getFilters = (route, category) => {
  const searchQuery = store.getters['category/getCurrentSearchQuery'](route, category)
  const result = []

  if (searchQuery.filters !== undefined) {
    for (const key of Object.keys(searchQuery.filters)) {
      if (Array.isArray(searchQuery.filters[key])) {
        searchQuery.filters[key].forEach(e => {
          result.push({ filterType: key, filterData: e })
        })
      }
    }
  }

  if (route.params.has_promotion_in_stores !== undefined) {
    result.push({ filterType: 'has_promotion_in_stores' })
  }

  if (route.params.sort !== undefined) {
    result.push({ filterType: 'sort', sortType: route.params.sort })
  }
  return result
}

const getSortMetaData = (sortType = '', filtersLength: number, currentCategory = null) => {
  const sortMetaData = { h1: '', title: '' }

  if (filtersLength > 1) {
    switch (sortType) {
      case 'final_price:asc': sortMetaData.h1 = String(i18n.t('cheaper')); break
      case 'final_price:desc': sortMetaData.h1 = String(i18n.t('expensive')); break
      case 'created_at:desc': sortMetaData.h1 = String(i18n.t('Novelties')).toLowerCase(); break
      default: sortMetaData.h1 = ''
    }
  } else {
    switch (sortType) {
      case 'final_price:asc': sortMetaData.h1 = currentCategory.name + ' (' + i18n.t('cheaper') + ')'; break
      case 'final_price:desc': sortMetaData.h1 = currentCategory.name + ' (' + i18n.t('expensive') + ')'; break
      case 'created_at:desc': sortMetaData.h1 = i18n.t('Novelties') + ': ' + currentCategory.name; break
      default: sortMetaData.h1 = currentCategory.name
    }
  }

  sortMetaData.title = sortMetaData.h1 + ' | VARUS'
  return sortMetaData
}

const getH1List = (filters = [], currentCategory = null) => {
  const attributes = store.getters['attribute/getAttributeListByCode']
  let list = ''

  filters.forEach((e, index) => {
    const separator = index ? '; ' : ''
    switch (e.filterType) {
      case 'sort': {
        const sortH1 = getSortMetaData(e.sortType, filters.length, currentCategory)?.h1
        if (sortH1.length) list += separator + sortH1;
        break
      }
      case 'price': list += separator + getPriceFilter(); break
      case 'has_promotion_in_stores':
        list += separator + i18n.t('Promotion on') + ' ' + currentCategory.name.toLowerCase(); break
      default: {
        if (attributes[e.filterType] !== undefined) {
          const filterLabel = attributes[e.filterType].frontend_label
          const filterValue = e.filterData.label
          if (list.includes(filterLabel + ' -')) {
            list += ', ' + filterValue
          } else {
            list += separator + filterLabel + ' - ' + filterValue
          }
        }
      }
    }
  })
  return list
}

const getIsUlrInExclusionList = () => {
  return [...exclusionUrlsListUa, ...exclusionUrlsListRu]
    .find(e => e.url === store.getters['url/getCurrentRoute'].fullPath)
}

export function getMetaContent (category = null, route = {}) {
  const currentCategory = category || {}
  const attributes = store.getters['attribute/getAttributeListByCode']

  let h1 = htmlDecode(currentCategory?.meta_h1)
  let additional = i18n.t('META_ADDITIONAL_TITLE') as string
  let description = htmlDecode(currentCategory?.meta_description)
  const filters = getFilters(route, currentCategory) || []

  // no filters
  if (!filters.length) {
    h1 = h1 || currentCategory?.name
  }

  // only one filter
  if (filters.length === 1) {
    const firstFilter = filters[0]
    switch (firstFilter.filterType) {
      case 'pim_brand_id':
        h1 = h1 || (currentCategory?.name + ' ' + firstFilter.filterData.label)
        additional = i18n.t('META_ADDITIONAL_TITLE_BRANDS') as string

        break
      case 'sort':
        const sortMetaData = getSortMetaData(firstFilter.sortType, filters.length, currentCategory)
        h1 = h1 || sortMetaData.h1
        break
      case 'has_promotion_in_stores':
        h1 = h1 || (i18n.t('Promotion on') + ' ' + currentCategory?.name.toLowerCase())
        break
      case 'price':
        const priceFilter = getPriceFilter()
        h1 = h1 || (currentCategory?.name + ': ' + priceFilter)
        break
      // all other types of filters
      default:
        h1 = h1 || (currentCategory?.name + ': ' + attributes[firstFilter.filterType]?.frontend_label + ' - ' + firstFilter.filterData.label)
    }
  }

  // multiple filters
  if (filters.length > 1) {
    h1 = currentCategory.name + ': ' + getH1List(filters, currentCategory)
  }

  let title = `${h1} ${additional}`
  description = description || h1 + ' ' + i18n.t('META_DESCRIPTION')

  // url in exclusionUrlsList
  const inExclusionListUrl = getIsUlrInExclusionList()
  const isUrlInExclusionList = !!inExclusionListUrl

  if (inExclusionListUrl) {
    h1 = inExclusionListUrl.h1
    title = inExclusionListUrl.title
  }

  return { h1, title, description, filters, isUrlInExclusionList }
}

export function metaCategory () {
  const currentCategory = this.currentCategory
  const route = this.$route
  const i18nStore = config.storeViews?.[this.$store.state.storeView.storeCode]?.i18n
  const currentLocale = i18nStore?.defaultLocale;
  const baseUrl = this.$store.getters['siteData/baseUrl'];
  const url = `${baseUrl}/${currentCategory?.url_path}`;
  const manifest = manifestMeta();
  const getBannersTop = this.$store.getters['promoted/getBannersTop'];
  const getProductPromotionBanners = this.$store.getters['promoted/getProductPromotionBanners'];
  const banners = [...getBannersTop, ...getProductPromotionBanners]
  const firstBanner = banners[0];
  let relRobots;

  if (route?.params.page || route?.query.page) {
    relRobots = relRobotsHandler(RobotsEnum.NOINDEX, config.xEnv.key)
  }

  if (route.query.sort) {
    relRobots = relRobotsHandler(RobotsEnum.NOINDEXNOFOLLOW, config.xEnv.key);
  }

  const metaContent = getMetaContent(currentCategory, route)

  const commonCount: any = Object.keys(this.getCurrentFilters).reduce((a, c) => {
    const length = this.getCurrentFilters[c].length
    a[c] = length
    a._commonLength += length

    return a
  }, {
    _commonLength: 0
  })

  const isBrandFilter = (commonCount._commonLength === 1 && commonCount?.pim_brand_id === 1)

  if (!isBrandFilter && commonCount._commonLength >= 1) {
    relRobots = relRobotsHandler(RobotsEnum.NOINDEXNOFOLLOW, config.xEnv.key);
  }

  if (getIsUlrInExclusionList()) {
    relRobots = relRobotsHandler(RobotsEnum.INDEX, config.xEnv.key);
  }

  const isRelRobots = relRobots || {}

  const meta = {
    title: metaContent.title,
    meta: {
      ...isRelRobots,
      title: {
        name: 'title',
        content: metaContent.title
      },
      description: {
        name: 'description',
        content: metaContent.description
      },
      ogType: {
        property: 'og:type',
        content: 'article'
      },
      ogTitle: {
        name: 'og:title',
        content: metaContent.title
      },
      ogDescription: {
        name: 'og:description',
        content: metaContent.description
      },
      ogUrl: {
        name: 'og:url',
        content: url
      },
      ogLocale: {
        name: 'og:locale',
        content: currentLocale
      }
    },
    link: [
      // temporarily hidden according to task VAR-3141
      // {
      //   rel: 'canonical',
      //   href: url
      // },
      {
        rel: 'preload',
        href: getThumbnailPath(firstBanner?.image_mobile) || getThumbnailPath(firstBanner?.image_desktop) || '',
        as: 'image'
      },
      ...manifest.link
    ]
  };

  return mergeMeta(meta)
}
