<template>
  <component :is="'script'" v-html="jsonld" type="application/ld+json" />
</template>

<script>
import { productImagePath } from 'theme/filters';
import { htmlDecode } from '@vue-storefront/core/filters';
import { mapGetters } from 'vuex';

export default {
  name: 'JsonLdCategoryList',
  props: {
    category: {
      type: Object,
      required: true
    },
    products: {
      type: Array,
      default: () => ([])
    }
  },
  computed: {
    ...mapGetters({
      breadcrumbsRoutes: 'breadcrumbs/getBreadcrumbsRoutes',
      getBreadcrumbsCurrent: 'breadcrumbs/getBreadcrumbsCurrent',
      getCurrentRoute: 'url/getCurrentRoute',
      baseUrl: 'siteData/baseUrl'
    }),
    jsonld () {
      return JSON.stringify(this.content, null, 2);
    },
    content () {
      const supersededLast = [...(this.breadcrumbsRoutes || [])]?.pop()

      const supersededBy = supersededLast ? {
        'supersededBy': {
          '@type': 'PhysicalActivityCategory',
          'name': supersededLast?.name
        }
      } : {}

      const products = [...this.products].map((item, index) => ({
        '@type': 'ListItem',
        'position': index + 1,
        'item': {
          '@type': 'Product',
          'url': `${this.baseUrl + this.getCurrentRoute.path}#product-${index + 1}`,
          'name': item.title,
          'image': [
            productImagePath(item.sku, 420, 420)
          ],
          'description': htmlDecode(item.description),
          'category': {
            '@type': 'PhysicalActivityCategory', /* you can scroll down this type and select the most appropriate type from the Enumeration members */
            'name': this.category.name,
            ...supersededBy
          },
          'offers': {
            '@type': 'Offer',
            'price': +(item.sqpp?.special_price || item?.sqpp?.price),
            'priceCurrency': 'UAH'
          }
        }
      }))

      return {
        '@context': 'https://schema.org/',
        '@type': 'ItemList',
        'itemListElement': products
      }
    }
  }
}
</script>
