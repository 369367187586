<template>
  <div
      class="m-filter-categories"
  >
<!--    <div-->
<!--      v-if="isLoading"-->
<!--      class="m-filter-categories-placeholder"-->
<!--    >-->
<!--      <div-->
<!--        v-for="index in placeholderCount"-->
<!--        :key="index"-->
<!--        class="m-filter-categories-placeholder__title placeholder-item-svg&#45;&#45;css-animation"-->
<!--      />-->
<!--    </div>-->
    <AFilterCategoryItems
      v-if="show"
      :items="preparedCategories"
      :level="1"
      :show-all-goods="showAllGoods"
      @select="categorySelectHandler"
    />
  </div>
</template>

<script>
import { prepareCategoryMenuItem } from 'theme/helpers';
import DeviceType from 'theme/mixins/DeviceType';
import AFilterCategoryItems from 'theme/components/atoms/a-filter-category-items';
import { mapGetters } from 'vuex';
import { isServer } from '@vue-storefront/core/helpers';
import config from 'config';

export default {
  name: 'CategoriesFilter',
  components: {
    AFilterCategoryItems
  },
  mixins: [DeviceType],
  props: {
    categories: { type: Array, required: true },
    show: { type: Boolean, required: false, default: true },
    isLoading: { type: Boolean, default: true },
    categoriesMap: { type: Object, default: () => ({}) },
    showAllGoods: { type: Boolean, default: false },
    productCountsByCategory: { type: Object, default: () => ({}) },
    currentCategory: { type: Object, default: () => ({}) }
  },
  data () {
    return {
      openedCategories: [],
      currentFilterCategories: [],
      timer: null
    }
  },
  mounted () {
    if (this.isMobile && this.currentFilterCategories.length) return;

    this.setSelectedCategory();
  },
  beforeDestroy () {
    clearInterval(this.autoScrollCategoryInterval);
  },
  provide () {
    const provided = {};

    // Reactive injection
    Object.defineProperties(provided, {
      activeCategoryId: {
        get: () => {
          return !this.isCurrentCategoryRoot ? (this.currentCategory?.id || null) : null
        }
      },
      openedCategories: {
        get: () => this.openedCategories
      },
      categoriesLength: {
        get: () => this.categoriesMap
      }
    })

    return { provided };
  },
  computed: {
    ...mapGetters({
      categoriesProductsCount: 'category-extension/getCategoriesProductsCount'
    }),
    preparedCategories () {
      return this.prepareCategories(this.categories);
    },
    allCategoriesArrayFromMap () {
      return Object.values(this.categoriesMap);
    },
    isCurrentCategoryRoot () {
      return this.currentCategory.parent_id === config.entities.category.categoriesRootCategoryId;
    },
    placeholderCount () {
      return 10;
    }
  },
  methods: {
    autoScrollCategory () {
      if (isServer) return

      this.autoScrollCategoryInterval = setInterval(() => {
        const activeLink = document.querySelector('.m-filter-categories .accordion-item__header--active');
        if (activeLink !== null) {
          const containerOffsetTop = document.querySelector('.m-filter-categories .sf-accordion').getBoundingClientRect().top;
          const elementOffsetTop = activeLink.getBoundingClientRect().top;
          const offsetTop = elementOffsetTop - containerOffsetTop;
          document.querySelector('.m-filter-categories .sf-accordion').scrollTo({
            top: offsetTop,
            left: 0,
            behavior: 'smooth'
          });
          clearInterval(this.autoScrollCategoryInterval);
        }
      }, 200);
    },
    prepareCategories (categories) {
      const prepareCategory = (category) => {
        const preparedCategory = prepareCategoryMenuItem(category);
        preparedCategory.countProduct = this.productCountsByCategory[preparedCategory.id] || 0;
        const childCategories = preparedCategory?.children_data;
        if (childCategories?.length > 0) {
          preparedCategory.items = this.prepareCategories(childCategories);
        }
        return preparedCategory;
      };

      return categories
        .map(prepareCategory)
        .sort((a, b) => a.position - b.position);
    },
    categorySelectHandler (category) {
      this.currentFilterCategories = category;
      this.openedCategories = category
        ? this.getCategoryNamesFromPath(category.path)
        : [];
      this.$emit('select', category);
    },
    getCategoryNamesFromPath (path) {
      const categoryIds = path.split('/');

      return this.allCategoriesArrayFromMap
        .filter((category) => categoryIds.includes(String(category.id)))
        .map((category) => category.name);
    },
    setSelectedCategory () {
      if (!this.allCategoriesArrayFromMap.length) return;

      const currentCategoryUrlKey = this.$route.path.split('/').pop();
      const currentCategory = this.allCategoriesArrayFromMap.find((category) => category.url_key === currentCategoryUrlKey);
      this.currentFilterCategories = currentCategory;
      this.openedCategories = currentCategory
        ? this.getCategoryNamesFromPath(currentCategory.path)
        : [];
    }
  },
  watch: {
    isLoading: {
      handler (val, oldVal) {
        if (val || val === oldVal) return;
        if (this.isMobile) return;

        this.setSelectedCategory();
      }
    },
    show (val, oldVal) {
      if (!val || val === oldVal || !this.isMobile) return
      this.$nextTick(() => {
        this.setSelectedCategory();
        this.autoScrollCategory()
      })
    },
    $route: {
      handler (val, oldVal) {
        if (val.path === oldVal.path) return;

        this.setSelectedCategory();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "~theme/css/px2rem";

.m-filter-categories {
  overflow: auto;
  padding: 0;
  margin: 0 0 18px;

  @include for-desktop {
    background: var(--accordion-background);
    border-radius: var(--spacer-10);
  }

  @media only screen and (min-width: $tablet-min) {
    margin: 0 0 10px;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  @-moz-document url-prefix() {
    scrollbar-width: none;
  }

  ::v-deep .accordion {
    font-family: Inter,serif;
    display: block;
    width: auto;
    align-items: center;
    padding-right: var(--spacer-5);
    padding-left: var(--spacer-20);
    padding-bottom:  var(--spacer-20);
    background: var(--accordion-background);
    border-radius: var(--spacer-10);
    scrollbar-width: thin;
    scrollbar-color:  rgba(235, 103, 71, 0.3);
    max-height: 21.25rem;
    overflow-y: scroll;

    &:hover {
      &::-webkit-scrollbar-thumb {
        background-color: var(--orange);
      }
    }

    @include for-desktop {
      padding-right: 0;
      padding-left: 0;
      background: transparent;
      border-radius: 0;

      .accordion-item {
        &__header:hover {
          color: var(--orange);
        }
      }
    }

    &::-webkit-scrollbar {
      width: 3px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(235, 103, 71, 0.3);
    }

    @include for-mobile {
      > .accordion-item {
        .accordion-item__first-level:before {
          position: absolute;
          left: -5px;
          bottom: 0;
          top: 0;
          right: 1px;
          background: var(--pale-orange2);
          content: ' '
        }
      }
    }

    &-item {
      &:first-child {
        @include for-desktop {
          margin-left: var(--spacer-20);
        }
      }

      &:not(:first-child) {

        @include for-desktop {
          margin-left: var(--spacer-20);
          margin-right: var(--spacer-20);
        }
      }

      &__header {
        color: var(--black);
        white-space: normal;
        padding: var(--spacer-20) 0 0;
        font-style: normal;
        font-weight: normal;
        font-size: var(--font-sm);
        display: inline-block;
        border-radius: var(--spacer-10);
        position: relative;
        width: 80%;
        line-height: var(--font-lg);
        transition: color 0.3s ease-in-out;
        cursor: pointer;

        .accordion-item__label {
          position: relative;

          &-count {
            color: var(--neutral-gray);
            padding-left: var(--spacer-10);
            position: absolute;
            left: 100%;
            bottom: 0;
            transition: color .3s ease-in-out;
          }
        }

        &--active {
          color: var(--orange);
          background-color: inherit;

          .accordion-item__label {

            &-count {
              color: var(--orange);
              transition: color .3s ease-in-out;
            }
          }
        }

        @include for-desktop {
          &:hover {
            .accordion-item__label {

              &-count {
                color: var(--orange);
              }
            }
          }
        }
      }

      .sf-accordion-item__content {
        padding-bottom: 0;
        padding-top: 0;
        border: 0 solid var(--gray);

        & > .accordion--sub {
          padding-left: var(--spacer-20);
          position: relative;
          &:before {
            content: '';
            position: absolute;
            top: 1px;
            left: 0;
            height: calc(100% + var(--spacer-17));
            width: 1px;
            background: var(--category-divider-color);
          }

          .accordion-item__first-level + .sf-accordion-item__content {
            border-left: none;

            @include for-desktop {
              position: relative;

              .sf-accordion-item:first-child a {
                padding-top: var(--spacer-20);
              }

              .sf-accordion-item:last-child a {
                padding-bottom: 0;
              }

              .accordion-item__header.is-open {
                border-radius: 0;
              }
            }
          }
        }
      }
    }
  }

  &-placeholder {
    background: var(--accordion-background);
    border-radius: var(--spacer-10);
    padding: var(--spacer-10) var(--spacer-20) var(--spacer-30);

    &__title {
      margin-top: var(--spacer-20);
      background: var(--placeholder-color);
      border-radius: var(--spacer-3);
      height: var(--spacer-20);
      width: 100%;
    }
  }
}
</style>
