<template>
  <div
    class="o-filter-markdown"
    :class="{'o-filter-markdown--disable': markdownCount === 0}"
  >
    <ACheckboxToggle
        :value="isFilterActive"
        :title="stockFilterLabel"
        @input="handleFilterChange"
    />
    <span class="o-filter-markdown__count">{{ markdownCount }}</span>
  </div>
</template>

<script>
import ACheckboxToggle from 'theme/components/atoms/a-checkbox-toggle';
import { attributes, products } from 'config';

export default {
  name: 'MCategoryFilterMarkdown',
  components: {
    ACheckboxToggle
  },
  props: {
    markdownCount: {
      type: Number,
      default: 0
    }
  },
  computed: {
    stockFilter () {
      return attributes.filterMarkdown;
    },
    stockFilterLabel () {
      return this.$t(attributes.filterMarkdown.label);
    },
    markdownFilterValues () {
      return products.markdownFilterValues;
    },
    isFilterActive () {
      return this.$route.query.hasOwnProperty(this.stockFilter.type);
    }
  },
  methods: {
    async handleFilterChange (value) {
      const filter = {
        type: this.stockFilter.type,
        id: this.markdownFilterValues[value]
      }
      this.$emit('markdown-filter-changed', filter);
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.o-filter-markdown {
  display: flex;
  margin-right: var(--spacer-sm);

  @include for-mobile {
    position: relative;
    margin: 0;
  }

  &__count {
    margin-top: var(--spacer-2);
    padding-left: var(--spacer-10);
    font-size: var(--font-size-14);
    color: var(--neutral-gray);

    @include for-mobile {
      margin-top: 0;
      font-weight: 500;
    }
  }

  &--disable {
    pointer-events: none;
    opacity: 0.7;
  }
}
</style>
