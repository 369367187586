<template>
  <div class="m-related-categories">
    <ul class="m-related-categories__items">
      <li
        class="m-related-categories__item"
        v-for="item in categories"
        :key="item.id"
        data-transaction-name="Related - Go To Category"
        @click="goToCategory(item.url)"
      >
        <span class="m-related-categories__name">
          {{ item.name }}
        </span>
        <SfOImage
          :src="item.image"
          :alt="item.name"
          class="m-related-categories__image"
          :class="!item.image && 'm-related-categories--plug'"
          lazy
        />
      </li>
    </ul>
  </div>
</template>

<script>
import SfOImage from 'theme/components/storefront-override/SfOImage';
import Fetch from '@vue-storefront/core/lib/isomorphic-fetch'

import {
  getCategoryImagePath
} from 'theme/helpers';
export default {
  components: { SfOImage },
  name: 'MRelatedCategories',
  props: {
    items: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data () {
    return {
      categories: []
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler (to, from) {
        if (from !== undefined && to.path !== from.path) {
          this.getCategories()
        }
      }
    }
  },
  created () {
    this.getCategories()
  },
  methods: {
    async getImage (id) {
      try {
        const getImage = getCategoryImagePath(id, 460, 200)
        return await Fetch(getImage)
      } catch (e) {
        return { status: 503, url: null }
      }
    },
    goToCategory (url) {
      this.$router.push(this.localizedRoute(`/${url}`))
    },
    async getCategories () {
      const savedCategories = []

      for (const item of this.items) {
        const { status, url } = await this.getImage(item.pimcore_id);
        savedCategories.push({
          ...item,
          image: status === 200 ? url : null
        })
      }

      this.categories = savedCategories
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~theme/css/breakpoints";
@import "~theme/css/px2rem";

.m-related-categories {
  margin-top: var(--spacer-50);

  @include for-desktop {
    margin-top: var(--spacer-60);
  }

  &--plug {
    background-color: var(--blue-light2);
    width: 100%;
    height: 100%;
  }

  &__items {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
    margin: 0;
    padding: 0;
    flex-wrap: wrap;
    list-style: none;
    cursor: pointer;
    grid-auto-rows: px2rem(74);

    @include for-desktop {
      grid-auto-rows: px2rem(100);
    }

    @include for-tablet {
      grid-auto-rows: px2rem(76);
    }

    @media (min-width: $tablet-min) {
      grid-template-columns: repeat(4, 1fr);
      gap: 10px;
    }
  }

  &__image {
    ::v-deep {
      img {
        height: px2rem(74);
        width: px2rem(350);
        object-fit: cover;

        @include for-desktop {
          height: px2rem(100);
        }

        @include for-tablet {
          max-height: px2rem(76);
        }
      }
    }
  }

  &__item {
    position: relative;
  }

  &__name {
    position: absolute;
    color: var(--black);
    font-size: var(--font-size-12);
    top: 26%;
    left: 15%;
    width: 60%;
    z-index: 1;

    @include for-desktop {
      font-size: var(--font-size-16);
    }
  }
}
</style>
